.root {
  align-items: center;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  /* Need a higher z-index to supersede the map component */
  z-index: 500;
}

.wrapper {
  border-bottom: 1px solid var(--color-decorative-secondary);
  flex-grow: 1;
  padding: var(--spacing-6);
  padding-bottom: 0;
}

.contentWrapper {
  display: flex;
  gap: var(--spacing-4);
  justify-content: flex-start;
  margin-bottom: var(--spacing-4);
  width: 100%;
}

.titleDescriptionActionsWrapper {
  display: flex;
  gap: var(--spacing-4);
  width: 100%;
}

.titleWrapper {
  align-items: center;
  display: flex;
  max-width: var(--content-extra-width);

  & .title {
    margin: 0;

    & input {
      font-size: var(--text-3xl);
      font-weight: var(--font-bold);
      line-height: var(--leading-3xl);
    }
  }

  & .secondaryText {
    align-self: center;
    margin-left: var(--spacing-4);
    margin-top: var(--spacing-3);
  }
}

.description {
  margin-top: var(--spacing-1-half);

  &.addMaxWidth {
    max-width: var(--content-max-width-v2);
  }

  /* Never have extra margin on the last <p> tag in the description */
  & p:last-child {
    margin-bottom: 0;
  }
}

.actions {
  align-items: flex-end;
  display: flex;
  /* Prevent the .actions div from shrinking on smaller browsers */
  flex-shrink: 0;
  gap: var(--spacing-3);
  /* Align to the right of the flexbox */
  margin-left: auto;

  /* Remove the pesky margin on the bottom of the action toolbar */
  & > div {
    margin: 0;
  }
}

.tabs {
  margin-bottom: 0;
  padding: 0 var(--spacing-6);
  width: 100%;
}

.children {
  border-bottom: var(--border-decorative-secondary);
  width: 100%;
}

@media (max-width: 880px) {
  .titleDescriptionActionsWrapper {
    align-items: flex-start;
    flex-direction: column;
    gap: var(--spacing-4);
  }

  .actions {
    margin-left: initial;
  }
}
