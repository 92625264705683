.root {
  & dd {
    margin-bottom: var(--spacing-2);

    & :last-child {
      margin-bottom: 0;
    }
  }

  & ol {
    padding-left: var(--spacing-4);
  }

  & ul {
    padding-left: 0em;
  }

  &.vertical {
    & :last-child {
      margin-bottom: 0;
    }
  }
}

.error {
  color: var(--color-danger);
  display: block;
  font-size: var(--text-xs);
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-2);
}

@media (min-width: 960px) {
  .root {
    /* TODO: Refactor away from using the horizontal layout in favor of the vertical */
    /* or update the horizontal layout to use use a different approach. This can be  */
    /* investigated during story creation in #3958 */
    &.horizontal {
      display: flex;
      flex-flow: row wrap;

      & dt {
        flex: 0 0 150px;
        margin-bottom: var(--spacing-2);
        padding-right: 1ex;
        text-align: right;

        &::after {
          content: ':';
        }
      }

      & dd {
        flex: 1 0 calc(100% - 150px);
      }
    }

    &.vertical {
      /* Add margin between the dt and dd */
      & dt {
        margin-bottom: var(--spacing-1);
      }

      /* Add margin between each dt/dd pair */
      & dd {
        margin-bottom: var(--spacing-4);
      }

      & dd:last-child {
        margin-bottom: 0;
      }
    }
  }
}
