.root {
  border-radius: var(--rounded-lg);
  display: flex;
  flex-direction: column;
  max-width: var(--content-max-width);
  position: relative;

  &.wide {
    max-width: var(--content-extra-width);
  }

  &.full {
    max-width: none;
  }
}

/* Add margin to consecutive cards */
.root + .root {
  margin-top: var(--spacing-8);
}

.default {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-decorative-secondary);
}

/* TODO: rename this to slate instead of disabled */
.disabled {
  background: var(--color-slate-50);
  border: 1px solid var(--color-decorative-secondary);
}

.selected {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-sky-700);
}

.sticky {
  position: sticky;
  top: var(--spacing-4);
}

.clickable {
  border-radius: var(--rounded-lg);
  color: inherit;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  text-align: inherit;
  transition: box-shadow var(--timing-300) ease-in-out;

  &:not(.isDisabled):hover {
    box-shadow: var(--drop-shadow-popover);
  }
}

.isDisabled {
  background-color: var(--color-slate-200);
  color: var(--color-slate-800);
  cursor: not-allowed;
  opacity: 50%;
}
