.root {
  --l-link-color: unset;
  --l-link-color-hover: unset;

  border-radius: var(--rounded);
  border-style: solid;
  border-width: 1px;
  display: flex;
  margin-bottom: var(--spacing-4);
  padding: var(--spacing-4);

  & a,
  button {
    background-color: transparent;
    /* Use border to underline instead of text-decoration to avoid a break in the
      underline in between the text and the ::after arrow. */
    border-bottom-color: var(--l-link-color);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: var(--l-link-color);
    cursor: pointer;
    font-weight: var(--font-medium);
    padding: 0;
    text-decoration: none;

    &:focus,
    &:hover {
      border-bottom-color: var(--l-link-color-hover);
      color: var(--l-link-color-hover);
    }
  }

  & .actions {
    margin-top: var(--spacing-4);

    /* Anchors and buttons that are passed to actions will be styled slightly differently */
    & a,
    button {
      border-color: transparent;
      display: inline-block;

      /* Add margin between buttons and anchors */
      & + a,
      & + button {
        margin-left: var(--spacing-6);
      }

      &::after {
        content: '➔';
        margin-left: var(--spacing-1);
      }

      &:focus,
      &:hover {
        border-bottom-color: var(--l-link-color-hover);
      }
    }
  }
}

.icon {
  height: var(--icon-size);
  padding: var(--spacing-px);
  width: var(--icon-size);
}

.content {
  margin-left: var(--spacing-3);
}

.title {
  font-weight: var(--font-medium);
}

.description {
  margin-top: var(--spacing-2);
}

.descriptionList {
  list-style-type: disc;
  margin: var(--spacing-2) 0 0 0;
  padding-left: var(--spacing-4);

  & li {
    margin-bottom: var(--spacing-1);
  }
}

.info {
  --l-link-color: var(--color-sky-800);
  --l-link-color-hover: var(--color-sky-600);

  background-color: var(--color-sky-50);
  border-color: var(--color-sky-600);
  color: var(--color-sky-700);

  & .icon {
    color: var(--color-sky-600);
  }

  & .title {
    color: var(--color-sky-800);
  }
}

.danger {
  --l-link-color: var(--color-red-800);
  --l-link-color-hover: var(--color-red-600);

  background-color: var(--color-red-50);
  border-color: var(--color-red-600);
  color: var(--color-red-700);

  & .icon {
    color: var(--color-red-600);
  }

  & .title {
    color: var(--color-red-800);
  }
}

.warning {
  --l-link-color: var(--color-yellow-800);
  --l-link-color-hover: var(--color-yellow-600);

  background-color: var(--color-yellow-50);
  border-color: var(--color-yellow-600);
  color: var(--color-yellow-700);

  & .icon {
    color: var(--color-yellow-600);
  }

  & .title {
    color: var(--color-yellow-800);
  }
}

.success {
  --l-link-color: var(--color-green-800);
  --l-link-color-hover: var(--color-green-600);

  background-color: var(--color-green-50);
  border-color: var(--color-green-600);
  color: var(--color-green-700);

  & .icon {
    color: var(--color-green-600);
  }

  & .title {
    color: var(--color-green-800);
  }
}
