.hasCreative {
  justify-content: flex-start;
}

.name {
  align-items: center;
  display: flex;
  gap: var(--spacing-3);
}

.title {
  flex-grow: 1;
  margin-bottom: 0;
}

.subtitle {
  font-weight: var(--font-medium);
}

/* Must truncate each line individually because multi line truncation won't work */
.truncate {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.circled {
  align-items: center;
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-slate-200);
  border-radius: var(--rounded-full);
  display: flex;
  font-size: var(--text-base);
  height: var(--avatar-size-md);
  justify-content: center;
  width: var(--avatar-size-md);
}
