/**
 * Global style variables, based on company style guide.
 */

:root {
  /*********************
  * Global colors
  * These are all the colors that can be utilized throughout app.
  * Utilizing a semantic color variable defined below is preferable
  * to directly specifying one of these colors.
  ********************/

  /* Rainbow colors */
  --color-red-800: #991b1b;
  --color-red-700: #b91c1c;
  --color-red-600: #dc2626;
  --color-red-500: #ef4444;
  --color-red-50: #fef2f2;
  --color-orange-700: #c2410c;
  --color-orange-600: #ea580c;
  --color-orange-500: #f97316;
  --color-orange-400: #fb923c;
  --color-orange-50: #fff7ed;
  --color-amber-700: #b45309;
  --color-amber-600: #d97706;
  --color-amber-500: #f59e0b;
  --color-amber-400: #fbbf24;
  --color-amber-200: #fde68a;
  --color-amber-50: #fffbeb;
  --color-yellow-800: #854d0e;
  --color-yellow-700: #a16207;
  --color-yellow-600: #ca8a04;
  --color-yellow-300: #fde047;
  --color-yellow-500: #eab308;
  --color-yellow-50: #fefce8;
  --color-lime-700: #4d7c0f;
  --color-lime-600: #65a30d;
  --color-lime-500: #84cc16;
  --color-lime-50: #f7fee7;
  --color-green-800: #166534;
  --color-green-700: #15803d;
  --color-green-600: #16a34a;
  --color-green-500: #22c55e;
  --color-green-50: #f0fdf4;
  --color-emerald-700: #047857;
  --color-emerald-600: #059669;
  --color-emerald-500: #10b981;
  --color-emerald-50: #ecfdf5;
  --color-teal-700: #0f766e;
  --color-teal-600: #0d9488;
  --color-teal-500: #14b8a6;
  --color-teal-50: #f0fdfa;
  --color-cyan-700: #0e7490;
  --color-cyan-600: #0891b2;
  --color-cyan-500: #06b6d4;
  --color-cyan-50: #ecfeff;
  --color-sky-950: #082f49;
  --color-sky-900: #0c4a6e;
  --color-sky-800: #075985;
  --color-sky-700: #0369a1;
  --color-sky-600: #0284c7;
  --color-sky-500: #0ea5e9;
  --color-sky-400: #38bdf8;
  --color-sky-300: #7dd3fc;
  --color-sky-200: #bae6fd;
  --color-sky-100: #e0f2fe;
  --color-sky-50: #f0f9ff;
  --color-blue-700: #1d4ed8;
  --color-blue-600: #2563eb;
  --color-blue-500: #3b82f6;
  --color-blue-50: #eff6ff;
  --color-indigo-700: #4338ca;
  --color-indigo-600: #4f46e5;
  --color-indigo-500: #6366f1;
  --color-indigo-50: #eef2ff;
  --color-violet-700: #6d28d9;
  --color-violet-600: #7c3aed;
  --color-violet-500: #8b5cf6;
  --color-violet-50: #f5f3ff;
  --color-purple-700: #7e22ce;
  --color-purple-600: #9333ea;
  --color-purple-500: #a855f7;
  --color-purple-50: #faf5ff;
  --color-fuchsia-700: #a21caf;
  --color-fuchsia-600: #c026d3;
  --color-fuchsia-500: #d946ef;
  --color-fuchsia-50: #fdf4ff;
  --color-pink-700: #be185d;
  --color-pink-600: #db2777;
  --color-pink-500: #ec4899;
  --color-pink-50: #fdf2f8;
  --color-rose-700: #be123c;
  --color-rose-600: #e11d48;
  --color-rose-500: #f43f5e;
  --color-rose-50: #fff1f2;

  /* Neutral colors */
  --color-slate-50: #f9fafb;
  --color-slate-100: #f1f5f9;
  --color-slate-200: #e2e8f0;
  --color-slate-300: #cbd5e1;
  --color-slate-400: #94a3b8;
  --color-slate-500: #64748b;
  --color-slate-600: #475569;
  --color-slate-700: #334155;
  --color-slate-800: #1e293b;
  --color-slate-900: #0f172a;
  --color-slate-950: #020617;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-transparent: transparent;

  /**
  * Additional colors
  * These colors should be replaced when this component is updated.
  */
  --color-gold: gold;
  --color-silver: silver;
  --color-bronze: #cd7f32;

  /* Icon colors */
  --color-icon-facebook: #4267b2;
  --color-icon-linkedin: #0077b5;
  --color-icon-twitter: #1da1f2;

  /**
  * Semantic colors
  * These colors are derivative of the global colors above.
  * Utilize these variables when possible for the UI elements they reference.
  */

  /* Used for links and button backgrounds */
  --color-action: var(--color-sky-700);

  /* Used for links and button background hovers */
  --color-action-hover: var(--color-sky-800);

  /* Used for heading text like headings, labels and column headers */
  --color-text-heading: var(--color-slate-900);

  /* Used for general body and input text */
  --color-text-body: var(--color-slate-600);

  /* Used for a light version of general body and input text */
  --color-text-body-inverted: var(--color-white);

  /* Used for non-decorative (interactive) items like borders and tooltips that must be accessible */
  --color-decorative-primary: var(--color-slate-500);

  /* Used for decorative borders */
  --color-decorative-secondary: var(--color-slate-300);

  /* Used for cards and surfaces */
  --color-bg-primary: var(--color-white);

  /* Used for alternate backgrounds */
  --color-bg-secondary: var(--color-slate-50);

  /* Used for form elements */
  --color-bg-tertiary: var(--color-slate-100);

  /* Primary colors */
  --color-primary-1: var(--color-sky-950);
  --color-primary-2: var(--color-sky-900);
  --color-primary-3: var(--color-sky-500);
  --color-primary-4: var(--color-sky-200);

  /* Secondary colors */
  --color-secondary-1: var(--color-amber-400);
  --color-secondary-2: var(--color-amber-200);
  --color-secondary-3: var(--color-orange-500);
  --color-secondary-4: var(--color-orange-300);

  /* Brand colors */
  --color-brand-primary: var(--color-sky-500);
  --color-brand-green: var(--color-emerald-500);
  --color-brand-yellow: var(--color-yellow-300);
  --color-brand-yellow-dark: var(--color-yellow-500);
  --color-brand-orange: var(--color-orange-400);
  --color-brand-warning: var(--color-orange-600);
  --color-brand-danger: var(--color-red-500);
  --color-brand-purple: var(--color-indigo-700);
  --color-brand-purple-light: var(--color-indigo-100);
  --color-brand-teal: var(--color-teal-500);

  /* Status */
  --color-danger: var(--color-red-700);
  --color-disabled: var(--color-slate-400);
  --color-info: var(--color-sky-500);
  --color-success: var(--color-green-700);
  --color-warning: var(--color-secondary-1);
  --color-text-disabled: var(--color-slate-500);
  --color-bg-disabled: var(--color-slate-50);

  /* Modal */
  --color-modal-backdrop: var(--color-black);

  /* Tooltip */
  --color-tooltip-background: var(--color-slate-800);
  --color-tooltip-text: var(--color-white);

  /* Badge */
  --color-badge: var(--color-red-600);

  /**
  * Google colors
  * These colors ar only utilized when displaying Google Ads.
  */
  --color-google-blue: #1a0dab;
  --color-google-decorative: #dadce0;
  --color-google-text-body: #4d5156;
  --color-google-text-heading: #202124;

  /**
   * Shadows, borders and spacing
   */

  /* Shadows */
  --drop-shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
  --drop-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  --drop-shadow-md: 0px 2px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 3px 0px rgba(0, 0, 0, 0.07);
  --drop-shadow-none: 0px 0px 0px 0px var(--color-black);
  /* Adding a new shadow style because our existing ones are a bit harsh */
  --drop-shadow-popover: 0px 0px 4px 0px rgba(0, 0, 0, 0), 0px 0px 24px 0px rgba(0, 0, 0, 0.05),
    0px 0px 10px 0px rgba(0, 0, 0, 0.04), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

  /* Border radius */
  --rounded-none: 0rem; /* 0px */
  --rounded-sm: 0.125rem; /* 2px */
  --rounded: 0.25rem; /* 4px */
  --rounded-md: 0.375rem; /* 6px */
  --rounded-lg: 0.5rem; /* 8px */
  --rounded-xl: 0.75rem; /* 12px */
  --rounded-2xl: 1rem; /* 16px */
  --rounded-3xl: 1.5rem; /* 24px */
  --rounded-full: 50%;

  /* Placeholder border
  * Used for elements that will have a border at some point, but not always
   */
  --border-active: 1px solid var(--color-action);
  --border-decorative-secondary: 1px solid var(--color-decorative-secondary);
  --border-transparent: 1px solid transparent;

  /**
   * Spacing
   * Our spacing values are created in multiples of 4.
   * ie: --spacing-2 is 2 x 4 = 8
   * Spacing values should be utilized for spacing properties, not widths, heights, borders, etc.
   */
  --spacing-px: 0.0625rem; /* 1px */
  --spacing-half: 0.125rem; /* 2px */
  --spacing-1: 0.25rem; /* 4px */
  --spacing-1-half: 0.375rem; /* 6px */
  --spacing-2: 0.5rem; /* 8px */
  --spacing-2-half: 0.625rem; /* 10px */
  --spacing-3: 0.75rem; /* 12px */
  --spacing-4: 1rem; /* 16px */
  --spacing-5: 1.25rem; /* 20px */
  --spacing-6: 1.5rem; /* 24px - Padding around containers and page content, horizontal space (gutter) in between objects */
  --spacing-6-half: 1.75rem; /* 28px */
  --spacing-8: 2rem; /* 32px - Vertical margin in between sections */
  --spacing-9: 2.25rem; /* 36px  */
  --spacing-12: 3rem; /* 48px  */

  /*********************
   * Typography
   ********************/

  /* Fonts */
  --font-body: 'Inter', sans-serif;
  --font-instagram: Helvetica, sans-serif;
  --font-google: Arial, Helvetica, sans-serif;

  /**
   * Font size
   * Base font size is 16px
   */
  --text-2xs: 0.625rem; /* 10px */
  --text-xs: 0.75rem; /* 12px */ /* --leading-xs */
  --text-sm: 0.875rem; /* 14px */ /* --leading-sm */
  --text-base: 1rem; /* 16px */ /* --leading-base */
  --text-lg: 1.125rem; /* 18px */ /* --leading-lg */
  --text-xl: 1.25rem; /* 20px */ /* --leading-lg */
  --text-2xl: 1.5rem; /* 24px */ /* --leading-2xl */
  --text-3xl: 2rem; /* 32px */ /* --leading-3xl */
  --text-4xl: 2.25rem; /* 36px */ /* --leading-4xl */
  --text-5xl: 3rem; /* 48px */ /* --leading-none */
  --text-6xl: 3.75rem; /* 60px */ /* --leading-none */

  /**
   * Line height (leading)
   * Leading values generally align with their --text-size equivalent.
   * Exceptions include: --text-xl, --text-5xl, --text-6xl
   */
  --leading-none: 1;
  --leading-xs: 1rem; /* 16px */
  --leading-sm: 1.25rem; /* 20px */
  --leading-base: 1.5rem; /* 24px */
  --leading-lg: 1.75rem; /* 28px */
  --leading-2xl: 2rem; /* 32px */
  --leading-3xl: 2.25rem; /* 36px */
  --leading-4xl: 2.5rem; /* 40px */

  /**
   * Instances of --leading-normal should be removed when refactoring components.
   * Keeping some instances for now to avoid refactoring components.
   */
  --leading-normal: 1.5;

  /* Font weight */
  --font-bold: 700;
  --font-semibold: 600;
  --font-medium: 500;
  --font-normal: 400;

  /*********************
   * Sizes
   ********************/

  /* Buttons */
  --button-icon-size: 20px;
  --button-size: 32px;
  --button-with-icon-size: 38px;

  /* Chips */
  --chip-height-size: 24px;

  /* Menu icons */
  --nav-icon-size: 18px;

  /* Avatar */
  --avatar-size-sm: 24px;
  --avatar-size-md: 32px;
  --avatar-size-lg: 48px;

  /* V2 avatar uses same format as spacing */
  --avatar-size-5: 20px;
  --avatar-size-6: 24px;
  --avatar-size-8: 32px;
  --avatar-size-12: 48px;

  /* Icon */
  /* TODO: replace --button-icon-size with this */
  --icon-size: 16px;

  /* TODO: revisit these icon sizes - many are one off usages  */
  --icon-size-sm: 12px;
  --icon-size-md: 24px;
  --icon-size-lg: 36px;
  --icon-size-xl: 48px;
  --icon-size-2xl: 60px;

  /* V2 icons use same format as spacing */
  --icon-size-5: 20px;
  --icon-size-6: 24px;

  /* Thumbnail */
  --thumbnail-size-2xs: 32px;
  --thumbnail-size-xs: 48px;
  --thumbnail-size-sm: 72px;
  --thumbnail-size-md: 96px;
  --thumbnail-size-lg: 192px;

  /* Content widths */
  --content-max-width: 35rem; /* 560px */
  --content-extra-width: 48rem; /* 768px */
  /* TODO: phase out --content-max-width and replace with v2 */
  --content-max-width-v2: 32rem; /* 512px */

  /* Modal width */
  --modal-width: var(--content-max-width-v2);
  --modal-width-lg: 64rem; /* 1024px */

  /* Drawer width */
  --drawer-width-112: 28rem; /* 448px */
  --drawer-width-168: 42rem; /* 672px */

  /* Tooltip width */
  --tooltip-width: 300px;

  /* Context menu width */
  --context-menu-min-width: 200px;

  /* Breakpoints */
  --breakpoint-sm: 480px; /* Phone portrait */
  --breakpoint-md: 640px; /* Tablet portrait, Phone landscape */
  --breakpoint-lg: 960px; /* Desktop, Tablet landscape */
  --breakpoint-xl: 1170px; /* Desktop large */

  /* Additional widths */
  --form-filter-width: 200px;

  /*********************
   * Animation
   ********************/

  /* Timing */
  --timing-300: 300ms;

  /* Scrollbar */
  --scrollbar-width: 8px;
}
