:global {
  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    font-size: var(--text-xs);
    line-height: var(--leading-xs);
  }
}

.root {
  display: block;

  & > label {
    display: block;
  }
}

.wrapper {
  --l-time-box-width: 100px;
  --l-input-width: 200px;

  & :global(.react-datepicker__input-container) {
    & input {
      width: var(--l-input-width);
    }
  }

  & :global(.react-datepicker__time-container) {
    width: var(--l-time-box-width);
  }

  & :global(.react-datepicker__close-icon) {
    padding-right: var(--spacing-2);
  }

  & :global(.react-datepicker__close-icon::after) {
    background-color: var(--color-transparent);
    color: var(--color-slate-600);
    font-size: var(--text-xl);
  }

  /* Extra specificity required to override FormElement style */
  & :global(.react-datepicker__tab-loop.react-datepicker__tab-loop) {
    flex-grow: 0;
  }

  /* Extra specificity required to override FormElement style */
  & :global(.react-datepicker-wrapper.react-datepicker-wrapper) {
    flex-grow: 0;
  }

  &.isFullWidth {
    width: 100%;

    & :global(.react-datepicker-wrapper) {
      width: 100%;
    }

    & :global(.react-datepicker__input-container input) {
      width: 100%;
    }
  }

  &.withCalendarIcon {
    & > div {
      position: relative;
    }

    & input {
      padding-left: var(--spacing-9);
      padding-right: var(--spacing-3);
    }
  }

  & :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box) {
    width: var(--l-time-box-width);
  }

  &
    :global(
      .react-datepicker
        .react-datepicker__navigation--next--with-time:not(
          .react-datepicker__navigation--next--with-today-button
        )
    ) {
    --l-right: 110px;

    right: var(--l-right);
  }
}

.datePicker {
  background-color: var(--color-bg-primary);
  background-image: none;
  border: 1px solid var(--color-decorative-secondary);
  border-radius: var(--rounded);
  box-shadow: none;
  font-size: var(--text-sm);
  height: 38px;
  line-height: var(--leading-sm);
  margin: 0;
  padding: var(--spacing-2) var(--spacing-2-half);
  transition:
    border-color ease-in-out 0.15s,
    boxshadow ease-in-out 0.15s;
}

.naked {
  background-color: transparent;
  border: none;
  padding: var(--spacing-2) 0 0 0;
  width: 100%;
}

.calendarIcon {
  left: var(--spacing-3);
  position: absolute;
  top: var(--spacing-3);
  z-index: 1;
}

.disabled {
  background-color: var(--color-bg-disabled);
  color: var(--color-text-disabled);
}
